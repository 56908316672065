import {useEffect, useState} from 'react'

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

export function useAddToHomeScreenPrompt(): [
  IBeforeInstallPromptEvent | null,
  () => void,
  'accepted' | 'dismissed' | null
] {
  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null)
  const [userChoiceState, setUserChoiceState] = useState<'accepted' | 'dismissed' | null>(null)

  const isRunningStandalone = window.matchMedia('(display-mode: standalone)').matches

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt()
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event')
    )
  }

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault()
      setPrompt(event as IBeforeInstallPromptEvent)
      setUserChoiceState(null) // Reset user choice when the event occurs
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    }
  }, [])

  useEffect(() => {
    if (prompt) {
      prompt.userChoice
        .then((choiceResult) => {
          setUserChoiceState(choiceResult.outcome)
        })
        .catch((error) => {
          console.error('Error occurred while handling userChoice:', error)
        })
    }
    if (isRunningStandalone) {
      setUserChoiceState('accepted')
    }
  }, [prompt, isRunningStandalone])

  return [prompt, promptToInstall, userChoiceState]
}
