import {
  Address,
  Delivery,
  IAddress,
  IDelivery,
  IPhotoUse,
  ISubscription,
  IUser,
  Photo,
  PhotoUse,
  Subscription,
  User,
} from 'models'

export enum CustomerStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
  on_waiting_list = 'on_waiting_list',
  full_capacity = 'full_capacity',
  out_of_range = 'out_of_range',
}

export enum GetCustomersRequestDtoSortEnum {
  firstName = 'customer.firstName',
  lastName = 'customer.lastName',
  status = 'customer.status',
  createdAt = 'customer.createdAt',
}

export interface ICustomer {
  id?: string
  createdAt?: string
  deliveries?: IDelivery[]
  updatedAt?: string
  firstName?: string
  lastName?: string
  passwordHash?: string
  status?: CustomerStatusEnum
  user?: IUser
  phone?: string
  birthDate?: Date
  mobileNumber?: string
  secondMobileNumber?: string
  description?: string
  email?: string
  subscriptions?: ISubscription[]
  addresses?: IAddress[]
  walletBalance?: number
  photoUses?: IPhotoUse[]
}

export class Customer {
  public props: ICustomer = {}

  constructor(data?: ICustomer) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt ? new Date(this.props.createdAt) : new Date()
  }

  getDeliveries(): Delivery[] {
    return this.props.deliveries?.map((item) => new Delivery(item)) || []
  }

  getBirthDate(): Date | null {
    return this.props.birthDate ? new Date(this.props.birthDate) : null
  }

  getEmail(): string {
    return this.props.email || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): CustomerStatusEnum {
    return this.props.status || CustomerStatusEnum.deactivated
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getMobileNumber(): string {
    return this.props.mobileNumber || ''
  }

  getSecondMobileNumber(): string {
    return this.props.secondMobileNumber || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getUser(): User {
    return new User(this.props.user || {})
  }

  getSubscriptions(): Subscription[] {
    return this.props.subscriptions?.map((item) => new Subscription(item)) || []
  }

  getAddresses(): Address[] {
    return this.props.addresses?.map((item) => new Address(item)) || []
  }

  getPhone = (): string => {
    return this.props?.phone || this.getUser().getMobileNumber() || ''
  }

  getFullName = (): string => {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : ''
  }

  getDisplayStatus(): string {
    return displayCustomerStatusEnum(this.getStatus())
  }

  getActiveAddress(): Address {
    return this.getAddresses().find((item) => item.getIsActive()) || new Address(undefined)
  }

  getWalletBalance(): number {
    return this.props.walletBalance || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }
}

export const displayCustomerStatusEnum = (adminStatusEnum: CustomerStatusEnum): string => {
  switch (adminStatusEnum) {
    case CustomerStatusEnum.activated:
      return 'فعال'
    case CustomerStatusEnum.deactivated:
      return 'غیر فعال'
    case CustomerStatusEnum.on_waiting_list:
      return 'لیست انتظار'
    case CustomerStatusEnum.out_of_range:
      return 'خارج از محدوده'
    case CustomerStatusEnum.full_capacity:
      return 'عدم ظرفیت کافی'
    default:
      return '-'
  }
}
