import {FC} from 'react'
import useStyles from './style'
import {Box} from '@mui/material'
import {useAddToHomeScreenPrompt} from 'hooks/useAddToHomeScreenPrompt'
import useWindowHeight from 'hooks/useWindowHeight'

const ContainerProvider: FC<{
  children: React.ReactNode
}> = ({children}) => {
  const {classes} = useStyles()
  const [prompt, promptToInstall, userChoiceState] = useAddToHomeScreenPrompt()
  const windowHeight = useWindowHeight()

  return (
    <div
      className={classes.root}
      style={
        userChoiceState === 'accepted'
          ? {minHeight: `calc(${windowHeight}px - 20px)`}
          : {minHeight: `calc(${windowHeight}px)`}
      }
    >
      <div
        className={classes.container}
        style={
          userChoiceState === 'accepted'
            ? {height: `calc(${windowHeight}px - 20px)`}
            : {height: `calc(${windowHeight}px)`}
        }
      >
        <Box
          height={
            userChoiceState === 'accepted'
              ? {height: `calc(${windowHeight}px - 20px)`}
              : {height: `calc(${windowHeight}px)`}
          }
          overflow={'auto'}
        >
          {children}
        </Box>
      </div>
    </div>
  )
}

export default ContainerProvider
