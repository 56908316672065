import {Box, IBox} from './Box'
import {IPackagingContentTarget, PackagingContentTarget} from './PackagingContentTarget'

export interface IPackagingTarget {
  id?: string
  createdAt?: string
  endDate?: string
  updatedAt?: string
  adminId?: string
  box?: IBox
  boxId?: string
  fruitWeight?: number
  vegetableWeight?: number
  status?: string
  packagingContentTargets?: IPackagingContentTarget[]
}

export class PackagingTarget {
  public props: IPackagingTarget = {}

  constructor(data?: IPackagingTarget) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getFruitWeight(): number {
    return this.props.fruitWeight || 0
  }

  getVegetableWeight(): number {
    return this.props.vegetableWeight || 0
  }

  getPackagingContentTargets(): PackagingContentTarget[] {
    return (this.props.packagingContentTargets || []).map(
      (packagingContentTarget) => new PackagingContentTarget(packagingContentTarget)
    )
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getStatus(): string {
    return this.props.status || ''
  }
}
