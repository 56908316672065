import {OptionsObject, SnackbarKey} from 'notistack'

let enqueueSnackbarFunction: ((message: string, options?: OptionsObject) => SnackbarKey) | null =
  null

export const initializeSnackbar = (
  enqueueSnackbar: (message: string, options?: OptionsObject) => SnackbarKey
) => {
  enqueueSnackbarFunction = enqueueSnackbar
}

export const showSnackbar = (message: string, options: OptionsObject = {}) => {
  if (enqueueSnackbarFunction) {
    enqueueSnackbarFunction(message, options)
  } else {
    console.error('Snackbar not initialized.')
  }
}
