import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {ScrollTop} from 'components/template/layout/scroll-top'
import {Content} from 'components/template/layout/content'
import {Sidebar} from 'components/template/layout/sidebar'
import {ThemeModeProvider} from 'providers/theme-mode/ThemeModeProvider'
import {reInitMenu} from 'utils/template/helpers'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const [pageTitle, setPageTitle] = useState<string>('')

  return (
    <>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            {/* <HeaderWrapper /> */}
            <div
              className='app-wrapper flex-column flex-row-fluid'
              id='kt_app_wrapper'
              style={{marginLeft: 0, position: 'relative'}}
            >
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </ThemeModeProvider>
    </>
  )
}

export {MasterLayout}
