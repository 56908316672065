import {Box, IBox} from './Box'

export interface ISubscriptionBoxes {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  box?: IBox
  boxId?: string
  packagingTargetId?: string
  quantity?: number
  subscriptionId?: string
  totalPrice?: number
}

export class SubscriptionBoxes {
  public props: ISubscriptionBoxes = {}

  constructor(data?: ISubscriptionBoxes) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }
}
