import {ICustomer} from 'models'
import {IDelivery} from './Delivery'

export interface PaymentTXNDetails {
  cardNumber?: string
  expiryDate?: string
  status?: string
  amount?: number
  type?: string
  source?: string
  refId?: string
  method?: string
  gateway?: string
  cardInfo?: {}
}

export interface IPayment {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  amount?: number
  txnDetails?: PaymentTXNDetails
  customer?: ICustomer
  deliveries?: IDelivery[]
}

export class Payment {
  public props: IPayment = {}

  constructor(data?: IPayment) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }
}
