import {FC} from 'react'
import {Navigate} from 'react-router-dom'

// constants
import {DELIVERY_CHAINS_ROUTE} from 'constants/routes'

// hooks
import useUser from 'hooks/useUser'

// components
import PublicLayout from 'components/template/layout/PublicLayout'
import queryString from 'query-string'

interface IProtectedRouteProps {}

const ProtectedRoute: FC<IProtectedRouteProps> = () => {
  const {isLogin} = useUser()

  return !isLogin ? (
    <PublicLayout />
  ) : (
    <Navigate
      replace
      to={queryString.stringifyUrl({
        url: DELIVERY_CHAINS_ROUTE,
      })}
    />
  )
}

export default ProtectedRoute
