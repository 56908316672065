import {IAddress, Address} from './Address'
import {IAdmin, Admin} from './Admin'

export interface IVendor {
  name?: string
  firstName?: string
  lastName?: string
  mobileNumber?: string
  adminId?: string
  purchaseCount?: number
  admin?: IAdmin
  addresses?: IAddress[]
  // photoUses?: IPhoto[]
  id?: string
  createdAt?: string
  updatedAt?: string
}

export class Vendor {
  public props: IVendor = {}

  constructor(data?: IVendor) {
    if (data) {
      this.props = data
    }
  }

  getName(): string {
    return this.props.name || ''
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getMobileNumber(): string {
    return this.props.mobileNumber || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }
  getPurchaseCount(): number {
    return this.props.purchaseCount || 0
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getAddresses(): Address[] {
    return this.props.addresses?.map((address) => new Address(address)) || []
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  // custome
  getFirstAddress(): Address {
    return new Address((this.props.addresses && this.props.addresses[0]) || {})
  }
}
