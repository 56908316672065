import {FC} from 'react'
import queryString from 'query-string'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {Box} from '@mui/material'
// utils
import {toAbsoluteUrl} from 'utils/template/helpers'
// Constant
import {DELIVERY_CHAINS_ROUTE} from 'constants/routes'
// component
import Image from 'components/elements/Image'
import {Typography} from 'components/elements/Typography'
import {Navbar} from 'components/template/layout/header/Navbar'
// locals
import {useStyles} from './useStyles'

interface IHeader {
  title?: string
}

const Header: FC<IHeader> = ({title = ''}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const {classes} = useStyles({location})

  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        zIndex={9}
        className={classes.totalNavigation}
      >
        <Box>
          <span
            onClick={() =>
              navigate(
                queryString.stringifyUrl({
                  url: DELIVERY_CHAINS_ROUTE,
                })
              )
            }
          >
            <Image
              src={toAbsoluteUrl('/media/sarsabad/svg/right-square-nav.svg')}
              alt='right-square-nav'
              width={26}
              height={26}
              disableDialog
              className={classes.backButton}
            />
          </span>
          <Image
            className={[classes.menuIcon, 'cursor-pointer'].join(' ')}
            src={toAbsoluteUrl('/media/sarsabad/svg/menu.svg')}
            alt='right-square-nav'
            width={22}
            height={22}
            disableDialog
            id='kt_app_sidebar_mobile_toggle'
          />
        </Box>

        <Box className={classes.centerBox}>
          {!!title ? (
            <Typography type='h5' color='#1F1F1F' className='mb-0'>
              {title}
            </Typography>
          ) : (
            <Link
              to={queryString.stringifyUrl({
                url: DELIVERY_CHAINS_ROUTE,
              })}
            >
              <Image
                src={toAbsoluteUrl('/media/logos/logo_small.svg')}
                alt='sarsabad-logo'
                width={40}
                height={40}
                disableDialog
              />
            </Link>
          )}
        </Box>
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between'
        >
          <Navbar />
        </div>
      </Box>
    </>
  )
}

export {Header}
