import {useEffect, useState} from 'react'
import {useAddToHomeScreenPrompt} from 'hooks/useAddToHomeScreenPrompt'

export const useData = () => {
  const [prompt, promptToInstall] = useAddToHomeScreenPrompt()
  const [isVisible, setVisible] = useState(false || location.pathname === '/login')

  const hide = () => setVisible(false)

  useEffect(() => {
    if (prompt) {
      setVisible(true)
    }
  }, [prompt])

  const handleInstall = () => {
    setVisible(false)
    promptToInstall()
  }

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isRunningStandalone = window.matchMedia('(display-mode: standalone)').matches

  useEffect(() => {
    if (!isRunningStandalone) {
      setVisible(true)
    }
  }, [isRunningStandalone])

  return {
    isVisible,
    hide,
    handleInstall,
    isIOS,
    isRunningStandalone,
  }
}
