import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
import {ICustomer, IDeliveryChain, IDeliveryChainCustomer, IDto} from 'models'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/delivery-app/delivery-chain/delivery-chains'

export const getDeliveryChains = (query?: StringifiableRecord): Promise<IDto<IDeliveryChain>> => {
  delete query?.date
  delete query?.status

  return axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix,
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )
}

export const getCustomersDeliveryChains = (
  deliveryChainId: string,
  query?: StringifiableRecord
): Promise<IDto<IDeliveryChainCustomer>> => {
  delete query?.date
  delete query?.status

  return axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/' + deliveryChainId + '/customers',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )
}

export const getDetailsCustomersDeliveryChains = (
  deliveryChainId: string,
  customerId: string
): Promise<ICustomer> =>
  axios.http.get(rootAddress + prefix + '/' + deliveryChainId + '/customers/' + customerId)

export const updateStartDeliveryChainOperation = (deliveryChainID: string) =>
  axios.http.patch(rootAddress + prefix + '/' + deliveryChainID + '/start')

export const updateFinishDeliveryChainOperation = (deliveryChainID: string) =>
  axios.http.patch(rootAddress + prefix + '/' + deliveryChainID + '/finish')
