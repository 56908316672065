import {
  IRegion,
  ICourier,
  Region,
  Courier,
  IDeliveryChainCustomer,
  DeliveryChainCustomer,
  DeliveryPlanShiftEnum,
} from 'models'
import {IRegionDeliveryPlan, RegionDeliveryPlan} from './RegionDeliveryPlan'

export enum DeliveryChainStatusEnum {
  pending = 'pending',
  start = 'start',
  finish = 'finish',
}

export interface IDeliveryChain {
  id?: string
  createdAt?: string
  updatedAt?: string
  capacity?: number
  date?: string
  isActive?: boolean
  status?: DeliveryChainStatusEnum
  regionId?: string
  courierId?: string
  region?: IRegion
  courier?: ICourier
  serviceNumber?: number
  regionDeliveryPlan?: IRegionDeliveryPlan
  deliveryChainCustomers?: IDeliveryChainCustomer[]
}

export class DeliveryChain {
  public props: IDeliveryChain = {}

  constructor(data?: IDeliveryChain) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getCapacity(): number {
    return this.props.capacity || 0
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getStatus(): DeliveryChainStatusEnum | undefined {
    return this.props.status
  }

  getServiceNumber(): number {
    return this.props.serviceNumber || 0
  }

  getRegionId(): string {
    return this.props.regionId || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getRegion(): Region {
    return new Region(this.props.region || {})
  }

  getCourier(): Courier {
    return new Courier(this.props.courier || {})
  }

  getRegionDeliveryPlan(): RegionDeliveryPlan {
    return new RegionDeliveryPlan(this.props.regionDeliveryPlan || {})
  }

  getDeliveryChainCustomers(): DeliveryChainCustomer[] {
    return (this.props.deliveryChainCustomers || []).map(
      (deliveryChainCustomer: IDeliveryChainCustomer) =>
        new DeliveryChainCustomer(deliveryChainCustomer)
    )
  }

  // custom
  getDisplayStatus(): string {
    return displayDeliveryChainStatusEnum(this.getStatus())
  }

  getDisplayeShift(): string {
    switch (this.getRegionDeliveryPlan().getDeliveryPlan().getShift()) {
      case DeliveryPlanShiftEnum.morning:
        return 'صبح'
      case DeliveryPlanShiftEnum.after_noon:
        return 'عصر'
    }
  }
}

export const displayDeliveryChainStatusEnum = (
  deliveryChainStatusEnum?: DeliveryChainStatusEnum
): string => {
  switch (deliveryChainStatusEnum) {
    case DeliveryChainStatusEnum.pending:
      return 'در حال پردازش'
    case DeliveryChainStatusEnum.start:
      return 'شروع'
    case DeliveryChainStatusEnum.finish:
      return 'تمام شده'
    default:
      return ''
  }
}
