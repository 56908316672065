import {ICustomer} from 'models'
import {IDelivery} from './Delivery'
import {IPhotoUse} from './PhotoUse'

export interface IWalletTransaction {
  id?: string
  createdAt?: string
  updatedAt?: string
  amount?: number
  refundDetails?: {}
  deliveryId?: string
  delivery?: IDelivery
  customerId?: string
  customer?: ICustomer
  photoUses?: IPhotoUse[]
}

export class WalletTransaction {
  public props: IWalletTransaction = {}

  constructor(data?: IWalletTransaction) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }
}
