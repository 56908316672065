import {IAddress, ICity, IDistrict, IProvince} from '../models'
import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/location'

export const getProvinces = (): Promise<IProvince[]> =>
  axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/provinces'}))

export const getDistricts = (): Promise<IDistrict[]> =>
  axios.http.get(`${rootAddress + prefix}/districts`)

export const getProvinceCities = (provinceId: string): Promise<ICity[]> =>
  axios.http.get(rootAddress + prefix + '/provinces/' + provinceId + '/cities')

export const getReverseGeoCoding = async (query: StringifiableRecord): Promise<IAddress> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/reverse-geocoding', query},
      {
        arrayFormat: 'comma',
      }
    )
  )
