export enum TransactionTypeEnum {
  buy_subscription = 'buy_subscription',
  refunded = 'refunded',
  wallet = 'wallet',
}

export enum TransactionStatusEnum {
  paid = 'paid',
  pending = 'pending',
  failed = 'failed',
  expired = 'expired',
  refunded = 'refunded',
}

export enum TransactionPaymentTypeEnum {
  credit = 'credit',
  online = 'online',
  wallet = 'wallet',
}
