import {IRegionDistrict, RegionDistrict} from './RegionDistrict'
import {IRegionDeliveryPlan, RegionDeliveryPlan} from './RegionDeliveryPlan'

export enum RegionStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export interface IRegion {
  id?: string
  createdAt?: string
  updatedAt?: string
  label?: string
  polygon?: string
  capacity?: number
  currentCustomersCount?: number
  servicesCount?: number
  status?: RegionStatusEnum
  regionDistricts?: IRegionDistrict[]
  regionDeliveryPlans?: IRegionDeliveryPlan[]
}

export class Region {
  public props: IRegion = {}

  constructor(data?: IRegion) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getLabel(): string {
    return this.props.label || ''
  }

  getPolygon(): string {
    return this.props.polygon || ''
  }

  getCapacity(): number {
    return this.props.capacity || 0
  }

  getCurrentCustomersCount(): number {
    return this.props.currentCustomersCount || 0
  }

  getServicesCount(): number {
    return this.props.servicesCount || 0
  }

  getStatus(): RegionStatusEnum {
    return this.props.status || RegionStatusEnum.deactivated
  }

  getRegionDistricts(): RegionDistrict[] {
    return (this.props.regionDistricts || []).map(
      (regionDistrict: IRegionDistrict) => new RegionDistrict(regionDistrict)
    )
  }

  getRegionDeliveryPlan(): RegionDeliveryPlan[] {
    return (this.props.regionDeliveryPlans || []).map(
      (regionDeliveryPlan: IRegionDeliveryPlan) => new RegionDeliveryPlan(regionDeliveryPlan)
    )
  }

  // custom
  getDisplayStatus(): string {
    return displayRegionStatusEnum(this.getStatus())
  }
}

export const displayRegionStatusEnum = (regionStatusEnum?: RegionStatusEnum): string => {
  switch (regionStatusEnum) {
    case RegionStatusEnum.activated:
      return 'فعال'
    case RegionStatusEnum.deactivated:
      return 'غیر فعال'
    default:
      return ''
  }
}
