import {useEffect, useState} from 'react'

function useWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    // Function to update the window height when the resize event occurs
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    // Attach the event listener to the window
    window.addEventListener('resize', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowHeight
}

export default useWindowHeight
