import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  gradiant: {
    background:
      'radial-gradient(115.94% 422.29% at -15.94% 108.5%, #0C923F 0%, #00461C 100%) !important',
    color: 'white !important',
  },
  treasury: {
    background: 'transparent !important',
    border: '1px solid black !important',
    color: '#00461C !important',
  },

  text: {
    background: '#FFF !important',
    color: '#1A73E8 !important',
  },
}))

export default useStyles
