import {FC, ReactNode} from 'react'

interface TypographyProps {
  className?: string
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p'
  color?: string
  children?: ReactNode
  onClick?: () => void
}

const Typography: FC<TypographyProps> = ({
  className,
  type,
  children = <></>,
  color = 'black',
  onClick = () => null,
}) => {
  switch (type) {
    case 'h1':
      return (
        <h1 className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2 className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h3 className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </h3>
      )
    case 'h4':
      return (
        <h4 className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </h4>
      )
    case 'h5':
      return (
        <h5 className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </h5>
      )
    case 'h6':
      return (
        <h6 className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </h6>
      )
    case 'span':
      return (
        <span className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </span>
      )
    case 'p':
      return (
        <p className={className} style={{color: `${color}`}} onClick={onClick}>
          {children}
        </p>
      )

    default:
      return null
  }
}

export {Typography}
