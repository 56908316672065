import {Suspense} from 'react'
import {LayoutProvider, LayoutSplashScreen} from 'providers/layout'
import ApiProvider from 'providers/api'
import TextProvider from 'providers/text'
import MessageProvider from 'providers/message'
import UserProvider from 'providers/user'
import ToolbarRefetchProvider from 'providers/toolbar-refetch'
import Router from 'routers'
import ContainerProvider from 'providers/container'
import {MasterInit} from 'components/template/layout/MasterInit'
import InstallApp from 'components/layout/InstallApp'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ContainerProvider>
        <LayoutProvider>
          <ApiProvider>
            <TextProvider>
              <MessageProvider>
                <UserProvider>
                  <ToolbarRefetchProvider>
                    <InstallApp />
                    <Router />
                    <MasterInit />
                  </ToolbarRefetchProvider>
                </UserProvider>
              </MessageProvider>
            </TextProvider>
          </ApiProvider>
        </LayoutProvider>
      </ContainerProvider>
    </Suspense>
  )
}

export {App}
