import {useEffect, useLayoutEffect, useRef} from 'react'
import {Tab} from 'bootstrap'
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from 'components/template/basic'
import {ThemeModeComponent} from 'components/template/theme'

import {useLayout} from 'providers/layout'
import useUser from 'hooks/useUser'

export function MasterInit() {
  const isFirstRun = useRef(true)
  const {isLogin} = useUser()

  const pluginsInitialization = () => {
    // isFirstRun.current = false
    ThemeModeComponent.init()
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
      SwapperComponent.bootstrap()
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab)
      })
    }, 500)
  }

  useLayoutEffect(() => {
    if (isLogin) {
      if (!!isFirstRun.current) {
        isFirstRun.current = false
        pluginsInitialization()
      }
    }
  }, [isLogin])

  return <></>
}
