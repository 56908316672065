import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: '100%',
      backgroundColor: 'black',
      margin: '0 auto',
    },
    container: {
      width: '100%',
      maxWidth: '480px',
      margin: '0 auto',
      backgroundColor: '#EFF6ED',
    },
  }
})

export default useStyles
