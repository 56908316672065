import {Customer, Delivery, DeliveryChain, ICustomer, IDelivery, IDeliveryChain} from 'models'

export interface IDeliveryChainCustomer {
  id?: string
  createdAt?: string
  updatedAt?: string
  priority?: number
  customerId?: string
  deliveryChainId?: string
  deliveryId?: string
  customer?: ICustomer
  deliveryChain?: IDeliveryChain
  delivery?: IDelivery
}

export class DeliveryChainCustomer {
  public props: IDeliveryChainCustomer = {}

  constructor(data?: IDeliveryChainCustomer) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getPriority(): number {
    return this.props.priority || -1
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getDeliveryChainId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getDeliveryChain(): DeliveryChain {
    return new DeliveryChain(this.props.deliveryChain || {})
  }

  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }
}
