import axios, {AxiosInstance} from 'axios'

import config from './config'
import storage from './storage'
import {showSnackbar} from '../providers/message/snackbarUtils'

export default class ClientApi {
  http: AxiosInstance

  constructor() {
    this.http = axios.create({
      baseURL: config.rootAddress,
      timeout: config.timeout,
    })

    this.http.interceptors.request.use(async (request) => {
      if (request && request.headers) {
        request.headers.Authorization = `Bearer ${storage.get(config.tokenName)}`
      }

      return request
    })

    this.http.interceptors.response.use(
      (response) => {
        const {token, verification_token} = response.data
        token && storage.set(config.tokenName, token)
        verification_token && storage.set(config.verification_token, verification_token)
        return response.data
      },
      (error) => {
        if (error.message === 'Network Error') {
          // If it's a network error, capture console logs and send them.
          showSnackbar('کانکشن ضعیف است. لطفا دوباره تلاش کنید!', {variant: 'error'})
        }
        return Promise.reject(error.response?.data)
      }
    )
  }
}
