import {FC} from 'react'
import {Navigate} from 'react-router-dom'

// hooks
import useUser from 'hooks/useUser'

// constants
import {LOGIN_ROUTE} from 'constants/routes'

// components
import {MasterLayout} from 'components/template/layout/MasterLayout'

interface IProtectedRouteProps {}

const ProtectedRoute: FC<IProtectedRouteProps> = () => {
  const {isLogin} = useUser()

  return isLogin ? <MasterLayout /> : <Navigate replace to={LOGIN_ROUTE} />
}

export default ProtectedRoute
