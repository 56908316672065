import {SubscriptionStatusEnum} from 'enums/subscription'
import {Customer, ICustomer} from './Customer'
import {Box, IBox} from './Box'
import {IPackagingTarget, PackagingTarget} from './PackagingTarget'
import {BoxPrice, IBoxPrice} from './BoxPrice'
import {Address, IAddress} from './Address'
import {IRegion, Region} from './Region'
import {DeliveryPlan, IDeliveryPlan} from './DeliveryPlan'
import {Admin, IAdmin} from './Admin'
import {ITransaction, Transaction} from './Transaction'
import {Delivery, IDelivery} from './Delivery'
import {ISubscriptionBoxes, SubscriptionBoxes} from './SubscriptionBoxes'

export interface ISubscription {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  totalPrice?: number
  weeklyCount?: number
  status?: SubscriptionStatusEnum
  description?: string | null
  regionId?: string
  boxPriceId?: string
  addressId?: string
  packagingTargetId?: string
  deliveryPlanId?: string
  customerId?: string
  adminId?: string
  boxId?: string
  customer?: ICustomer
  box?: IBox
  packagingTarget?: IPackagingTarget
  boxPrice?: IBoxPrice
  address?: IAddress
  region?: IRegion
  deliveryPlan?: IDeliveryPlan
  admin?: IAdmin
  transactions?: ITransaction[]
  deliveries?: IDelivery[]
  subscriptionBoxes?: ISubscriptionBoxes[]
}

export class Subscription {
  public props: ISubscription = {}

  constructor(data?: ISubscription) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getWeeklyCount(): number {
    return this.props.weeklyCount || 0
  }

  getStatus(): SubscriptionStatusEnum {
    return this.props.status as SubscriptionStatusEnum
  }

  getDescription(): string | null {
    return this.props.description || ''
  }

  getRegionId(): string {
    return this.props.regionId || ''
  }

  getBoxPriceId(): string {
    return this.props.boxId || ''
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getDeliveryPlanId(): string {
    return this.props.deliveryPlanId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getPackagingTarget(): PackagingTarget {
    return new PackagingTarget(this.props.packagingTarget || {})
  }

  getBoxPrice(): BoxPrice {
    return new BoxPrice(this.props.boxPrice || {})
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getRegion(): Region {
    return new Region(this.props.region || {})
  }

  getDeliveryPlan(): DeliveryPlan {
    return new DeliveryPlan(this.props.deliveryPlan || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getTransactions(): Transaction[] {
    return (this.props.transactions || []).map(
      (transaction: ITransaction) => new Transaction(transaction)
    )
  }

  getDeliveries(): Delivery[] {
    return (this.props.deliveries || []).map((delivery: IDelivery) => new Delivery(delivery))
  }

  getSubscriptionBoxes(): SubscriptionBoxes[] {
    return (this.props.subscriptionBoxes || []).map(
      (subscriptionBoxes: ISubscriptionBoxes) => new SubscriptionBoxes(subscriptionBoxes)
    )
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */
  getStatusDisplay(): string {
    switch (this.props.status) {
      case SubscriptionStatusEnum.pending:
        return 'در انتظار پرداخت'
      case SubscriptionStatusEnum.accepted:
        return 'تایید پرداخت'
      case SubscriptionStatusEnum.expired:
        return 'منقضی شده'
      case SubscriptionStatusEnum.rejected:
        return 'برگشت داده شده'
      case SubscriptionStatusEnum.delivered:
        return 'تحویل داده شده'
      case SubscriptionStatusEnum.replace:
        return 'جایگزین شده'
      case SubscriptionStatusEnum.cancel:
        return 'لغو شده'
      default:
        return ''
    }
  }
}
