import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: '100%',
    backgroundSize: '100%',
    paddingTop: '5rem',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
}))

export default useStyles
