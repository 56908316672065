export interface IDistrict {
  id?: string
  label?: string
}

export class District {
  public props: IDistrict = {}

  constructor(data?: IDistrict) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getLabel(): string {
    return this.props.label || ''
  }
}
