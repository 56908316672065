import {Admin, IAdmin} from './Admin'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'

export enum BoxCreatorTypeEnum {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
}

export enum BoxStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum BoxTypeEnum {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export interface IBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  name?: string
  description?: string
  weight?: number
  price?: number
  startDate?: string
  endDate?: string
  adminId?: string
  admin?: IAdmin
  photoUses?: IPhotoUse[]
  subscribersCount?: number
  creatorType?: BoxCreatorTypeEnum
  customerId?: string
  status?: BoxStatusEnum
  type?: BoxTypeEnum
}

export class Box {
  public props: IBox = {}

  constructor(data?: IBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getSubscribersCount(): number {
    return this.props.subscribersCount || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrder() - b.getOrder())
  }

  getMainImage(): Photo {
    return this.getPhotoUses().length > 0 ? this.getPhotoUses()[0].getPhoto() : new Photo()
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getStartDate(): Date | undefined {
    return this.props.startDate ? new Date(this.props.startDate) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getCreatorType(): BoxCreatorTypeEnum {
    return this.props.creatorType as BoxCreatorTypeEnum
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getStatus(): BoxStatusEnum {
    return this.props.status as BoxStatusEnum
  }

  getType(): BoxTypeEnum {
    return this.props.type as BoxTypeEnum
  }
}
