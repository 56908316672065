import React, {createContext, useContext, useEffect, useState} from 'react'
import {ThemeModeComponent} from 'components/template/theme'
import {toAbsoluteUrl} from 'utils/template/helpers'

export type ThemeModeType = 'dark' | 'light' | 'system'
export const themeModelSKey = 'kt_theme_mode_value'
export const themeMenuModeLSKey = 'kt_theme_mode_menu'

const systemMode = 'light'

type ThemeModeContextType = {
  mode: 'light'
  menuMode: 'light'
  updateMode: (_mode: 'light') => void
  updateMenuMode: (_mode: 'light') => void
}

const themeModeSwitchHelper = (_mode: 'light') => {
  // change background image url
  const mode = _mode
  const imageUrl = '/media/patterns/header-bg' + (mode === 'light' ? '.jpg' : '-dark.png')
  document.body.style.backgroundImage = `url("${toAbsoluteUrl(imageUrl)}")`
}

const defaultThemeMode: ThemeModeContextType = {
  mode: 'light',
  menuMode: 'light',
  updateMode: (_mode: 'light') => {},
  updateMenuMode: (_menuMode: 'light') => {},
}

const ThemeModeContext = createContext<ThemeModeContextType>({
  mode: 'light',
  menuMode: 'light',
  updateMode: (_mode: 'light') => {},
  updateMenuMode: (_menuMode: 'light') => {},
})

const useThemeMode = () => useContext(ThemeModeContext)

const ThemeModeProvider = ({children}: {children: React.ReactNode}) => {
  const [mode, setMode] = useState<'light'>(defaultThemeMode.mode)
  const [menuMode, setMenuMode] = useState<'light'>(defaultThemeMode.menuMode)

  const updateMode = (_mode: 'light', saveInLocalStorage: boolean = true) => {
    setMode('light')
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeModelSKey, _mode)
    }

    if (saveInLocalStorage) {
      const updatedMode = _mode
      document.documentElement.setAttribute('data-theme', updatedMode)
    }
    ThemeModeComponent.init()
  }

  const updateMenuMode = (_menuMode: 'light', saveInLocalStorage: boolean = true) => {
    setMenuMode('light')
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode)
    }
  }

  useEffect(() => {
    updateMode('light', true)
    updateMenuMode('light', true)
  }, [])

  return (
    <ThemeModeContext.Provider value={{mode, menuMode, updateMode, updateMenuMode}}>
      {children}
    </ThemeModeContext.Provider>
  )
}

export {ThemeModeProvider, useThemeMode, systemMode, themeModeSwitchHelper}
