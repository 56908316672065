import {TransactionStatusEnum, TransactionTypeEnum} from 'enums/transaction'
import {Customer, ICustomer} from './Customer'

export interface ITransaction {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  amount?: number
  type?: TransactionTypeEnum
  status?: TransactionStatusEnum
  subscriptionId?: string
  customerId?: string
  customer?: ICustomer
}

export class Transaction {
  public props: ITransaction = {}

  constructor(data?: ITransaction) {
    if (data) {
      this.props = data
    }
  }
  getId(): string {
    return this.props.id || ''
  }
  getCreatedAt(): Date {
    return this.props.createdAt ? new Date(this.props.createdAt) : new Date()
  }
  getUpdatedAt(): Date {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : new Date()
  }
  getAmount(): number {
    return this.props.amount || 0
  }
  getType(): TransactionTypeEnum {
    return this.props.type as TransactionTypeEnum
  }
  getStatus(): TransactionStatusEnum {
    return this.props.status as TransactionStatusEnum
  }
  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }
  getCustomerId(): string {
    return this.props.customerId || ''
  }
  getCustomer(): Customer {
    return new Customer(this.props.customer || undefined)
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */

  getCreatedAtTimeDisplay(): string {
    const date = this.getCreatedAt()
    const hours = date.getHours() // => 9
    const minuts = date.getMinutes() // =>  30

    return `${hours}:${minuts}`
  }

  getAmountDisplay(): string {
    return this.props.amount + ' تومان'
  }

  getStatusDisplay(): string {
    switch (this.props.status) {
      case TransactionStatusEnum.pending:
        return 'در انتظار پرداخت'
      case TransactionStatusEnum.paid:
        return 'پرداخت شده'
      case TransactionStatusEnum.expired:
        return 'منقضی شده'
      case TransactionStatusEnum.failed:
        return 'با مشکل مواجه شده'
      case TransactionStatusEnum.refunded:
        return 'بازگشت داده شده'
      default:
        return ''
    }
  }
}
