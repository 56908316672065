export interface IVehicle {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  name?: string
  model?: string
  plateNumber?: string
  courierId?: string
}

export class Vehicle {
  public props: IVehicle = {}

  constructor(data?: IVehicle) {
    if (data) {
      this.props = data
    }
  }
  getId(): string {
    return this.props.id || ''
  }
  getName(): string {
    return this.props.name || ''
  }
  getModel(): string {
    return this.props.model || ''
  }
  getPlateNumber(): string {
    return this.props.plateNumber || ''
  }
  getCourierId(): string {
    return this.props.courierId || ''
  }
  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }
  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }
}
