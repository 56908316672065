import * as auth from './auth'
import * as location from './location'
import * as upload from './upload'
import * as couriers from './couriers'
import * as deliveryChains from './delivery-chains'
import * as delivery from './delivery'

export interface ServerError {
  statusCode: number
  message: string
}

const services = {
  auth,
  location,
  upload,
  couriers,
  deliveryChains,
  delivery,
}

export default services
