import {FC} from 'react'

// mui
import {Skeleton} from '@mui/material'

// components
import {Modal} from 'components/elements/Modal'

// models
import {Photo} from 'models'

// locals
import {useData} from './useData'
import {assets} from './assets'

type ImageProps = {
  photo?: Photo
  className?: string
  width?: number
  height?: number
  dialogWidth?: number
  dialogHeight?: number
  disableDialog?: boolean
  isLoading?: boolean
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const Image: FC<ImageProps> = ({
  photo = new Photo(),
  className,
  width = 50,
  height = 50,
  dialogWidth = 500,
  dialogHeight = 500,
  disableDialog = true,
  isLoading = false,
  ...props
}) => {
  const {avatarSrc, isLoading: isLoadingImage, openDialog, setOpenDialog} = useData(photo)

  const renderDialog = () => {
    return (
      <Modal
        id='create-product-modal'
        show={!(isLoading || isLoadingImage) && openDialog && !disableDialog}
        handleClose={() => setOpenDialog(false)}
        className='d-flex justify-content-center align-items-center'
      >
        <img
          {...props}
          className={[className, 'margin-auto'].join(' ')}
          src={props.src ? props.src : avatarSrc || assets.images.avatar.src}
          width={dialogWidth}
          height={dialogHeight}
          alt=' '
        />
      </Modal>
    )
  }
  const renderLoading = () => {
    return <Skeleton variant='rectangular' width={width} height={height} />
  }

  const render = () => {
    return (
      <img
        {...props}
        onClick={() => setOpenDialog(true)}
        className={className}
        src={props.src ? props.src : avatarSrc || assets.images.avatar.src}
        width={width}
        height={height}
        alt=' '
      />
    )
  }

  return (
    <>
      {(isLoading || isLoadingImage) && renderLoading()}
      {!(isLoading || isLoadingImage) && render()}
      {renderDialog()}
    </>
  )
}

export default Image
