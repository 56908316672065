import {FC} from 'react'
import {toAbsoluteUrl} from 'utils/template/helpers'
import useStyles from './useStyles'
import clsx from 'clsx'

type ButtonProps = {
  variant?: 'gradient' | 'treasury' | 'text' | 'normal'
  fullWidth?: boolean
  className?: string
  isLoading?: boolean
  text?: string
  loadingText?: string
  iconSource?: string
  type?: 'button' | 'submit' | 'reset' | undefined
} & React.HTMLProps<HTMLButtonElement>

const Button: FC<ButtonProps> = ({
  fullWidth = false,
  variant = 'normal',
  className,
  isLoading = false,
  text,
  loadingText = 'لطفا منتظر بمانید...',
  type = undefined,
  iconSource,
  ...props
}) => {
  const {classes} = useStyles()

  return (
    <button
      {...props}
      type={type}
      className={clsx([
        className,
        variant === 'text' ? classes.text : '',
        variant === 'gradient' ? classes.gradiant : '',
        variant === 'treasury' ? classes.treasury : '',
        fullWidth ? 'w-100' : '',
        'btn btn-primary d-flex flex-row justify-content-center align-items-center gap-2',
      ])}
    >
      {iconSource && (
        <img className='align-middle ms-1' src={toAbsoluteUrl(iconSource)} alt='support' />
      )}
      {!isLoading && <span>{text}</span>}
      {!isLoading && props.children && props.children}
      {isLoading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {loadingText} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}

export {Button}
