import {FC} from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {createWebStoragePersistor} from 'react-query/createWebStoragePersistor-experimental'
import {persistQueryClient} from 'react-query/persistQueryClient-experimental'
import {ReactQueryDevtools} from 'react-query/devtools'

interface IApiProviderProps {
  children?: JSX.Element
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 30, // 30 day
    },
  },
})

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
})

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
})

const ApiProvider: FC<IApiProviderProps> = ({children}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export const cacheQuery = queryClient.getQueryCache()

export default ApiProvider
