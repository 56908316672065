//enums
import {CourierStatusEnum} from 'enums/courier'
//models
import {
  IVehicle,
  Photo,
  IPhotoUse,
  PhotoUse,
  IUser,
  User,
  Vehicle,
  CourierWeeklyPlan,
  ICourierWeeklyPlan,
} from 'models'

export interface ICourier {
  firstName?: string
  lastName?: string
  birthDate?: Date
  capacity?: number
  serviceCount?: number
  isLoggedIn?: boolean
  status?: CourierStatusEnum
  workingStartDate?: string
  workingEndDate?: string
  id?: string
  user?: IUser
  photoUses?: IPhotoUse[]
  vehicles?: IVehicle[]
  courierWeeklyPlans?: ICourierWeeklyPlan[]
  createdAt?: Date
  updatedAt?: Date
  email?: string
}

export class Courier {
  public props: ICourier = {}

  constructor(data?: ICourier) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getCapacity(): number {
    return this.props.capacity || 0
  }

  getEmail(): string {
    return this.props.email || ''
  }

  getBirthDate(): Date | undefined {
    return this.props.birthDate ? new Date(this.props.birthDate) : undefined
  }

  getServiceCount(): number {
    return this.props.serviceCount || 0
  }

  getIsLoggedIn(): boolean {
    return this.props.isLoggedIn || false
  }

  getStatus(): CourierStatusEnum {
    return this.props.status as CourierStatusEnum
  }

  getWorkingStartDate(): Date | undefined {
    return this.props.workingStartDate ? new Date(this.props.workingStartDate) : undefined
  }

  getWorkingEndDate(): Date | undefined {
    return this.props.workingEndDate ? new Date(this.props.workingEndDate) : undefined
  }

  getCourierWeeklyPlans(): CourierWeeklyPlan[] {
    return this.props.courierWeeklyPlans?.map((item) => new CourierWeeklyPlan(item)) || []
  }

  getUser(): User {
    return new User(this.props.user || {})
  }

  getVehicles(): Vehicle[] {
    return (this.props.vehicles || []).map((vehicle: IVehicle) => new Vehicle(vehicle))
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }

  getMainImage(): Photo {
    // if (this.props.photoUses && this.props.photoUses.length > 0) {
    //   return new PhotoUse(this.props.photoUses[0]).getPhoto()
    // }
    // return new Photo()
    const photos = this.getPhotoUses()
    if (photos.length > 0) return photos[0].getPhoto()
    else return new Photo()
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */

  getFullName = (): string => {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : ''
  }

  getDisplayPlateNumber = (): string => {
    return `${this?.getVehicles()?.[0]?.getName()}- ${this?.getVehicles()?.[0]
      ?.getPlateNumber()
      .replace(/(ایران)/, '-$1')}`
  }

  getWeeklyPlanIds(): string[] {
    return this.getCourierWeeklyPlans().map((item) => item.getId())
  }

  getStatusDisplay(): string {
    return this.props.status === CourierStatusEnum.activated ? 'فعال' : 'غیرفعال'
  }
}
