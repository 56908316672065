import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '480px',
      zIndex: 1000,
      margin: 'auto',
    },

    container: {
      backgroundColor: '#fff',
      borderRadius: 8,
    },

    content: {
      padding: theme.spacing(1, 2),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
    },
    buttonGroup: {
      margin: theme.spacing(4, 0, 1, 0),
      '& button': {
        flex: 1,
      },
    },
  }
})

export default useStyles
