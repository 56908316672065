import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {ICourier, IDto} from 'models'
import {ICourierIssue} from 'models/CourierIssue'
// dtos
import {CreateCourierIssueRequestDto, UpdateCourierIssueRequestDto} from 'dtos'

const {rootAddress} = apiConfig
const prefix = '/delivery-app/couriers'

const axios = new ClientApi()

export const getCourierIssue = (query?: StringifiableRecord): Promise<IDto<ICourierIssue>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/courier-issue',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

export const createCourierIssue = (body: CreateCourierIssueRequestDto) =>
  axios.http.post(rootAddress + prefix + '/courier-issue', body)

export const getCourierIssueDetail = (courierIssueId: string): Promise<ICourierIssue> =>
  axios.http.get(rootAddress + prefix + '/courier-issue' + courierIssueId)

export const updateCourierIssue = (courierIssueId: string, body: UpdateCourierIssueRequestDto) =>
  axios.http.post(rootAddress + prefix + '/courier-issue' + courierIssueId, body)

export const getCourierDetails = async (): Promise<ICourier> =>
  await axios.http.get(rootAddress + prefix)

export const updateCourier = async (body: {
  fristName?: string
  lastName?: string
  birthDate?: string
  email?: string
  vehicle?: {
    name?: string
    model?: string
    plateNumber?: string
  }
  photoId?: string
  weeklyPlanIds?: string[]
}): Promise<ICourier> => await axios.http.put(rootAddress + prefix, body)
