import {Admin, IAdmin} from './Admin'
import {IPhoto, Photo} from './Photo'

export interface IVariety {
  name?: string
  adminId?: string
  admin?: IAdmin
  photoUses?: IPhoto
  description?: string
  id?: string
  createdAt?: string
  updatedAt?: string
  subsetCount?: number
}

export class Variety {
  public props: IVariety = {}

  constructor(data?: IVariety) {
    if (data) {
      this.props = data
    }
  }

  getName(): string {
    return this.props.name || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPhotoUses(): Photo {
    return new Photo(this.props.photoUses || {})
  }

  getId(): string {
    return this.props.id || ''
  }

  getSubsetCount(): number {
    return this.props.subsetCount || 0
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }
}
