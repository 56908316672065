import {
  ICustomer,
  IBoxPrice,
  IPackagingTarget,
  IAddress,
  BoxPrice,
  PackagingTarget,
  Address,
  Customer,
  IAdmin,
  IPayment,
  IWalletTransaction,
  Payment,
  Admin,
  WalletTransaction,
  Box,
  IBox,
} from 'models'
import {DeliveryBox, IDeliveryBox} from './DeliveryBox'

export enum DeliveryStatusEnum {
  processing = 'processing',
  success = 'success',
  cancel = 'cancel',
  customer_absence = 'customer_absence',
}

export enum DeliveryStateEnum {
  pending = 'pending',
  rejected = 'rejected',
  // The label is ready to be printed and attached to the parcel.
  ready = 'ready',
  // The parcel has arrived at the destination and is out for delivery.
  out_for_delivery = 'out_for_delivery',
  // Your parcel is in transit to the destination location.
  in_transit = 'in_transit',
  // The courier was unable to deliver the parcel or contact the consignee to arrange delivery. The receiver should reach out and schedule a delivery or arrange a pickup from a local courier facility.
  failed_delivery = 'failed_delivery',
  // Your parcel has been delivered to the destination location.
  delivered = 'delivered',
}

export enum DeliveryTypeEnum {
  for_self = 'for_self',
  for_others = 'for_others',
}

export interface IDelivery {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  status?: DeliveryStatusEnum
  state?: DeliveryStateEnum
  type?: DeliveryTypeEnum
  confirmCode?: string
  notes?: string
  routePointNumber?: string
  redeliveryId?: string
  paymentId?: string
  addressId?: string
  customerId?: string
  adminId?: string
  boxPriceId?: string
  packagingTargetId?: string
  courierId?: string
  courierCode?: string
  customerNote?: string
  deliveryChainCustomerId?: string
  subscriptionId?: string
  redelivery?: IDelivery
  redeliveries?: IDelivery[]
  payment?: IPayment
  boxPrice?: IBoxPrice
  packagingTarget?: IPackagingTarget
  address?: IAddress
  customer?: ICustomer
  admin?: IAdmin
  walletTransactions?: IWalletTransaction[]
  deliveryBoxes?: IDeliveryBox[]
}

export class Delivery {
  public props: IDelivery = {}

  constructor(data?: IDelivery) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getStatus(): DeliveryStatusEnum | undefined {
    return this.props.status
  }

  getState(): DeliveryStateEnum | undefined {
    return this.props.state
  }

  getType(): DeliveryTypeEnum | undefined {
    return this.props.type
  }

  getConfirmCode(): string {
    return this.props.confirmCode || ''
  }

  getNotes(): string {
    return this.props.notes || ''
  }

  getRoutePointNumber(): string {
    return this.props.routePointNumber || ''
  }

  getReDeliveryId(): string {
    return this.props.redeliveryId || ''
  }

  getPaymentId(): string {
    return this.props.paymentId || ''
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getBoxPriceId(): string {
    return this.props.boxPriceId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getCourierCode(): string {
    return this.props.courierCode || ''
  }

  getCustomerNote(): string {
    return this.props.customerNote || ''
  }

  getDeliveryChainCustomerId(): string {
    return this.props.deliveryChainCustomerId || ''
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getReDelivery(): Delivery {
    return new Delivery(this.props.redelivery || {})
  }

  getReDeliveries(): Delivery[] {
    return (this.props.redeliveries || []).map((redelivery: IDelivery) => new Delivery(redelivery))
  }

  getPayment(): Payment {
    return new Payment(this.props.payment || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getBoxPrice(): BoxPrice {
    return new BoxPrice(this.props.boxPrice || {})
  }

  getPackagingTarget(): PackagingTarget {
    return new PackagingTarget(this.props.packagingTarget || {})
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getWalletTransactions(): WalletTransaction[] {
    return (this.props.walletTransactions || []).map(
      (walletTransaction: IWalletTransaction) => new WalletTransaction(walletTransaction)
    )
  }

  getDeliveryBoxes(): DeliveryBox[] {
    return (this.props.deliveryBoxes || []).map((box) => new DeliveryBox(box))
  }

  // custom
  getDisplayStatus(): string {
    return displayDeliveryStatusEnum(this.getStatus())
  }

  getDisplayState(): string {
    return displayDeliveryStatusEnum(this.getStatus())
  }
}

export const displayDeliveryStatusEnum = (deliveryStatusEnum?: DeliveryStatusEnum): string => {
  switch (deliveryStatusEnum) {
    case DeliveryStatusEnum.processing:
      return 'در نوبت'
    case DeliveryStatusEnum.cancel:
      return 'لغو شده'
    case DeliveryStatusEnum.customer_absence:
      return 'نبود مشتری'
    case DeliveryStatusEnum.success:
      return 'انجام شده'
    default:
      return ''
  }
}

export const displayDeliveryStateEnum = (deliveryStateEnum?: DeliveryStateEnum): string => {
  switch (deliveryStateEnum) {
    case DeliveryStateEnum.pending:
      return 'در حال پردازش'
    case DeliveryStateEnum.rejected:
      return 'رد شده'
    case DeliveryStateEnum.ready:
      return 'آماده'
    case DeliveryStateEnum.out_for_delivery:
      return 'در حال ارسال'
    case DeliveryStateEnum.in_transit:
      return 'در حال بارگذاری'
    case DeliveryStateEnum.failed_delivery:
      return 'ارسال ناموفق'
    case DeliveryStateEnum.delivered:
      return 'ارسال شده'
    default:
      return ''
  }
}
