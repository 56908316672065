import {lazy} from 'react'
import {Navigate, useRoutes} from 'react-router-dom'

import {
  // public
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  FORGOT_PASSWORD_SUCCESS_ROUTE,
  FORGOT_PASSWORD_CONFIRM_ROUTE,
  NOT_FOUND_ROUTE,

  // protected
  DELIVERY_CHAINS_ROUTE,
  DELIVERY_CHAINS_DETAILS_ROUTE,
} from 'constants/routes'

import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'

import ProtectedLayout from 'pages/protected'
import PublicLayout from 'pages/public'
import queryString from 'query-string'
import {getDateStringForQuery} from 'utils/basic/date'

// public
const LoginPage = lazy(() => import('pages/public/Login'))
const ForgotPasswordLayout = lazy(() => import('pages/public/ForgotPassword'))
const ForgotPasswordPage = lazy(() => import('pages/public/ForgotPassword/main'))
const ForgotPasswordConfirmPage = lazy(() => import('pages/public/ForgotPassword/confirm'))
const ForgotPasswordSuccessPage = lazy(() => import('pages/public/ForgotPassword/success'))

const ErrorPage = lazy(() => import('pages/public/Error'))

// protected
const DashboardLayout = lazy(() => import('pages/protected/Dashboard'))
const CouriersWeeklyPlansPage = lazy(() => import('pages/protected/WeeklyPlans'))

export const Routes = () =>
  useRoutes([
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <ProtectedLayout />,
          children: [
            {
              path: DELIVERY_CHAINS_ROUTE,
              element: <DashboardLayout />,
            },
            {
              path: DELIVERY_CHAINS_DETAILS_ROUTE,
              element: <CouriersWeeklyPlansPage />,
            },

            {
              path: '/',
              element: (
                <Navigate
                  to={queryString.stringifyUrl({
                    url: DELIVERY_CHAINS_ROUTE,
                  })}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <PublicRoute />,
      children: [
        {
          path: '/',
          element: <PublicLayout />,
          children: [
            {
              path: LOGIN_ROUTE,
              element: <LoginPage />,
            },
            {
              path: FORGOT_PASSWORD_ROUTE,
              element: <ForgotPasswordLayout />,
              children: [
                {
                  path: FORGOT_PASSWORD_ROUTE,
                  element: <ForgotPasswordPage />,
                },
                {
                  path: FORGOT_PASSWORD_SUCCESS_ROUTE,
                  element: <ForgotPasswordSuccessPage />,
                },
                {
                  path: FORGOT_PASSWORD_CONFIRM_ROUTE,
                  element: <ForgotPasswordConfirmPage />,
                },
              ],
            },
            {
              path: '/',
              element: <Navigate to={LOGIN_ROUTE} />,
            },
          ],
        },
      ],
    },
    {
      path: NOT_FOUND_ROUTE,
      element: <ErrorPage />,
    },
  ])
