import {Box, IBox} from './Box'

export interface IBoxPrice {
  id?: string
  box?: IBox
  createdAt?: string
  updatedAt?: string
  isActive?: boolean
  price?: number
  startDate?: string
  endDate?: string
  boxId?: string
  adminId?: string
}

export class BoxPrice {
  public props: IBoxPrice = {}

  constructor(data?: IBoxPrice) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getStartDate(): Date | undefined {
    return this.props.startDate ? new Date(this.props.startDate) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }
}
