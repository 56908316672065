import {Admin, IAdmin} from './Admin'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {IVariety, Variety} from './Variety'
import {IVendor, Vendor} from './Vendor'

export enum ProductTypeEnum {
  fruit = 'fruit',
  vegetable = 'vegetable',
}

export interface IProduct {
  id?: string
  createdAt?: string
  updateAt?: string
  name?: string
  type?: ProductTypeEnum
  description?: string
  icon?: string | null
  adminId?: string
  admin?: IAdmin
  averageRefuse?: number
  lastPrice?: number
  photoUses?: IPhotoUse[]
  vendor?: IVendor
  varieties?: IVariety[]
}

export class Product {
  public props: IProduct = {}

  constructor(data?: IProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getType(): ProductTypeEnum | undefined {
    return this.props.type
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getVendor(): Vendor {
    return new Vendor(this.props.vendor || {})
  }

  getAverageRefuse(): number {
    return this.props.averageRefuse || 0
  }

  getLastPrice(): number {
    return this.props.lastPrice || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }

  getVarieties(): Variety[] {
    return (this.props.varieties || []).map((variety: IVariety) => new Variety(variety))
  }

  // custom

  getDisplayType(): string {
    return displayProductTypeEnum(this.getType())
  }
}

export const displayProductTypeEnum = (productTypeEnum?: ProductTypeEnum): string => {
  switch (productTypeEnum) {
    case ProductTypeEnum.fruit:
      return 'میوه'
    case ProductTypeEnum.vegetable:
      return 'سبزی'
    default:
      return ''
  }
}
