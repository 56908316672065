import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDelivery} from 'models'
// dtos
import {
  CheckConfirmationCodeRequestDto,
  CreateInformationConflictRequestDto,
  UpdateCustomerAddressRequestDto,
  UpdateDeliveryRequestDto,
  UpdateInformationConflictRequestDto,
} from 'dtos'

const {rootAddress} = apiConfig
const prefix = '/delivery-app/delivery'

const axios = new ClientApi()

export const getDeliveryInformationConflictDetail = async (informationConflictId: string) =>
  await axios.http.get(rootAddress + prefix + '/information-conflict' + '/' + informationConflictId)

export const updateDeliveryInformationConflictDetail = async (
  informationConflictId: string,
  body: UpdateInformationConflictRequestDto
) =>
  await axios.http.put(
    rootAddress + prefix + '/information-conflict' + '/' + informationConflictId,
    body
  )

export const getDeliveryInformationConflict = async (query?: StringifiableRecord) =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/information-conflict',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

export const createDeliveryInformationConflict = async (
  body: CreateInformationConflictRequestDto
) => await axios.http.post(rootAddress + prefix + '/information-conflict', body)

export const updateAddressDetail = async (
  deliveryId: string,
  addressId: string,
  body: UpdateCustomerAddressRequestDto
) => await axios.http.put(rootAddress + prefix + '/' + deliveryId + '/addresses/' + addressId, body)

export const getDeliveyDetails = async (deliveyId: string): Promise<IDelivery> =>
  await axios.http.get(rootAddress + prefix + '/' + deliveyId)

export const updateDelivery = async (deliveryId: string, body: UpdateDeliveryRequestDto) =>
  await axios.http.put(rootAddress + prefix + '/' + deliveryId, body)

export const updateCheckConfirmationCode = async (
  deliveryID: string,
  body: CheckConfirmationCodeRequestDto
): Promise<{confirmed: boolean}> =>
  await axios.http.patch(rootAddress + prefix + '/' + deliveryID + '/check-confirmation-code', body)

export const updateDeliveryStatus = async (deliveryId: string, status: string) =>
  await axios.http.patch(rootAddress + prefix + '/' + deliveryId + '/update-status', {
    status,
  })
