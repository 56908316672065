import {Theme} from '@mui/material'
import {Location} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

type Params = {
  location: Location
}
export const useStyles = makeStyles<Params>()((theme: Theme, {location}) => {
  return {
    totalNavigation: {
      padding: '16px',
      borderRadius: '0 0 16px 16px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 4px 8px 0 #0000000A',
      position: 'fixed',
      top: 0,
      maxWidth: 480,
    },
    centerBox: {
      position: 'absolute',
      top: '50%',
      right: '50%',
      transform: 'translate(50%, -50%)',
    },
    menuIcon: {
      marginRight: '10px',
      transition: 'all .2s ease',
      position: 'relative',
      right: location.pathname === '/dashboard' ? '-3rem' : '0',
    },

    backButton: {
      visibility: location.pathname === '/dashboard' ? 'hidden' : 'visible',
      position: 'relative',
      right: location.pathname === '/dashboard' ? '-4rem' : '0',
      opacity: location.pathname === '/dashboard' ? '0' : '1',
      transition: 'all .2s .1s ease',
      cursor: 'pointer',
      '& svg': {
        width: '20px',
        height: '20px',
      },
      '& path': {
        fillOpacity: '.6',
      },
    },
  }
})
