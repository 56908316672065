export interface IPhoto {
  id?: string
  filename?: string
  mimetype?: string
  size?: number
}

export class Photo {
  public props: IPhoto = {}

  constructor(data?: IPhoto) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getFilename(): string {
    return this.props.filename || ''
  }

  getMimetype(): string {
    return this.props.mimetype || ''
  }

  getSize(): number {
    return this.props.size || 0
  }
}
