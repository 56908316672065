import ClientApi from './clientApi'
import config from './config'
import storage from './storage'
// models
import {ICourier, IUser} from 'models'
// dtos
import {
  CourierLoginRequestDto,
  CourierResetPasswordRequestDto,
  CourierSentVerificationCodeRequestDto,
  CourierVerifyVerificationCodeRequestDto,
} from 'dtos'

export interface ICurrentUserData {
  user: IUser
  courier: ICourier
}

const {rootAddress} = config

const prefix = '/delivery-app/auth'

const axios = new ClientApi()

export const sentVerificationCode = (
  body: CourierSentVerificationCodeRequestDto
): Promise<{mobileNumber: string}> =>
  axios.http.post(rootAddress + prefix + '/sent-verification-code', body)

export const verifyVerificationCode = (body: CourierVerifyVerificationCodeRequestDto) =>
  axios.http.post(rootAddress + prefix + '/verify-verification-code', body)

export const resetPassword = (body: CourierResetPasswordRequestDto) =>
  axios.http.post(rootAddress + prefix + '/reset-password', {
    ...body,
    token: storage.get(config.verification_token),
  })

export const currentUser = (): Promise<ICurrentUserData> =>
  axios.http.get(rootAddress + prefix + '/current-user')

export const login = (
  body: CourierLoginRequestDto
): Promise<{
  status?: string
  token?: string
}> => axios.http.post(rootAddress + prefix + '/login', body)
