import {FC, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {Box} from '@mui/material'
// hooks
import useUser from 'hooks/useUser'
import useWindowHeight from 'hooks/useWindowHeight'
import {useAddToHomeScreenPrompt} from 'hooks/useAddToHomeScreenPrompt'
// component
import {Header} from 'components/layout/Header'
// locals
import useStyles from './useStyles'

const ProtectedLayout: FC = () => {
  const {getUserInfo} = useUser()

  const {classes} = useStyles()

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const [prompt, promptToInstall, userChoiceState] = useAddToHomeScreenPrompt()
  const windowHeight = useWindowHeight()

  return (
    <>
      <Box
        className={classes.container}
        style={
          userChoiceState === 'accepted'
            ? {height: `calc(${windowHeight}px - 20px)`}
            : {height: `calc(${windowHeight}px)`}
        }
      >
        <Header />
        <Box className={classes.content}>
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default ProtectedLayout
