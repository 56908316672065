import {ICity, City} from './City'

export interface IProvince {
  id?: string
  createdAt?: string
  updatedAt?: string
  label?: string
  cities?: ICity[]
}

export class Province {
  public props: IProvince = {}

  constructor(data?: IProvince) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getLabel(): string {
    return this.props.label || ''
  }

  getCites(): City[] {
    return this.props.cities?.map((city) => new City(city)) || []
  }
}
