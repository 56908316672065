import {SidebarMenuItem} from './SidebarMenuItem'
import {DELIVERY_CHAINS_ROUTE} from 'constants/routes'

import useText from 'hooks/useText'

import {texts} from './texts'
import queryString from 'query-string'

const SidebarMenuMain = () => {
  const {TX} = useText()

  return (
    <>
      <SidebarMenuItem
        to={queryString.stringifyUrl({
          url: DELIVERY_CHAINS_ROUTE,
        })}
        icon='/media/icons/duotune/art/art002-sarsabad.svg'
        title={TX(texts.dashboard)}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuMain}
