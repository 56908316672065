import React, {FC} from 'react'
import {Modal} from 'components/elements/Modal'
import {Button} from 'components/elements/Button'
import useText from 'hooks/useText'
import useStyles from './style'
import {texts} from './texts'
import {Typography} from 'components/elements/Typography'
import {useData} from './useData'
import {KTSVG} from 'utils/template/helpers'

interface IInstallAppProps {}

const InstallApp: FC<IInstallAppProps> = () => {
  const {classes} = useStyles()
  const {TX} = useText()
  const {isVisible, hide, handleInstall, isIOS, isRunningStandalone} = useData()

  const renderInstallButton = () => {
    if (isIOS && !isRunningStandalone) {
      return (
        <div className={classes.container}>
          <div className={classes.content}>
            <Typography type='h4' className='fw-700 text-center'>
              {TX(texts.title)}
              <br />
              راه‌های زیر رو انجام دهید تا اضافه بشود!
            </Typography>
            <br />
            <Typography type='h4' className='fw-700 text-right'>
              <Typography type='span' color='#E87B1E'>
                ۱.
              </Typography>{' '}
              در ابتدا سایت را در اپلیکیشن Safari بالا بیاورید.
            </Typography>
            <Typography type='h4' className='fw-700 text-right'>
              <Typography type='span' color='#E87B1E'>
                ۲.
              </Typography>{' '}
              برروی آیکون <KTSVG path='/media/sarsabad/svg/IOS_share.svg' /> کلیک کنید.
            </Typography>
            <Typography type='h4' className='fw-700 text-right'>
              <Typography type='span' color='#E87B1E'>
                ۳.
              </Typography>{' '}
              برروی گزینه‌ی '
              <KTSVG className='svg-icon-0' path='/media/sarsabad/svg/IOS_plus.svg' />
              Add to Home Screen' کلیک کنید.
            </Typography>
            <Typography type='h4' className='fw-700 text-right'>
              <Typography type='span' color='#E87B1E'>
                ۴.
              </Typography>
              در آخر برروی گزینه‌ی 'Add' کلیک کنید.
            </Typography>
            <div className='d-flex flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-5'>
              <Button onClick={hide}>باشه</Button>
            </div>
          </div>
        </div>
      )
    }
    if (isVisible) {
      return (
        <div className={classes.container}>
          <div className={classes.content}>
            <Typography type='h4' className='fw-700 text-center'>
              {TX(texts.title)}
            </Typography>
            <div className='d-flex flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-5'>
              <Button variant='treasury' onClick={hide} className='py-3'>
                {TX(texts.no)}
              </Button>
              <Button onClick={handleInstall}>{TX(texts.yes)}</Button>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal id='install app' title={TX(texts.modal_title)} show={isVisible} handleClose={hide}>
      {renderInstallButton()}
    </Modal>
  )
}

export default InstallApp
